import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook8/image/imgPortadaEbook8.png"
import img1 from "@components/pageEbook/ebooks/ebook8/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook8/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook8/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook8/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook8/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook8/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook8/image/imgSilder4.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Guía completa para una buena experiencia de compra del cliente
    </h1>
  </>
)



const textDescription = (
  <div>
    <p className="text-h2-ebook">
    Se estima que el 86% de los consumidores pagaría más por tener una buena experiencia de compra. Por eso, es importante implementar ya determinadas estrategias.
    <br /> <br />
Mejorar la experiencia de compra para un negocio es fundamental para poder aumentar las ventas y fidelizar más clientes. Siendo conscientes de este impacto, en este e-book aprenderás a cómo lograrlo.

  </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
Para construir una experiencia de compra se requiere de un conglomerado de tácticas y estrategias de todo tu equipo de marketing 
<br /> <br />
Desde facilitar el acceso a la información a los clientes hasta la implementación de tecnología como chatbots. La experiencia de compra debe abarcar muchas aristas. 
</p>
)
const textTwo = (
  <p>
  Existen muchas herramientas digitales que ayudan a una buena experiencia de compra. Por ejemplo, la API de WhatsApp, chatbots, livechat o plataformas omnicanales. 
  <br /> <br />
Tu equipo siempre debe de trabajar en contjunto con la tecnología, para que tu negocio brinde una experiencia de compra inolvidable. </p>
)
const textThree = (
  <p>
Los KPIs son clave para saber el rendimiento de tu experiencia de compra. Por eso, en esta sección conocerás 10 indicadores ideales para tu negocio. 
 </p>
)

const data = {
  start: {
    support: "Ebook | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-8",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "Pasos para mejorar la experiencia de compra",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Herramientas para una experiencia de compra digital",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Indicadores para medir la experiencia de compra",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Saber lo que realmente significa una experiencia de compra.",
      },
      {
        key: 2,
        text: "Crear tu propia estrategia para mejorar la experiencia de compra en 8 pasos.",
      },
      {
        key: 3,
        text: "Reconocer las herramientas para una experiencia de compra digital.",
      },
      {
        key: 4,
        text: "Conocer y aplicar los indicadores para medir la experiencia de compra.",
      },
      {
        key: 5,
        text: "Mejorar la experiencia de compra para poder aumentar ventas y fidelizar más clientes.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
